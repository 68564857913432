<template>
  <section>
    <section class="container-header">
      <section class="text-container">
        <h3 class="title">{{ title }}</h3>
        <div class="subtitle">
          <p v-for="(value, key, index) in subtitles" :key="index">
            <span>
              {{ key }}
            </span>
            {{ value }}
          </p>
        </div>
      </section>
      <div class="btn-title-container">
        <b-button
          class="mr-2 delete"
          v-if="selection"
          size="xs"
          variant="outline-danger"
          v-b-tooltip.hover title="Cancelar geração do lote"
          @click="stopGenerateLotsBySelection"
        >  
          <Trash class="icon trash" />
        </b-button>

        <b-button
          v-if="selection && !selecteds.length"
          variant="outline-primary"
          disabled
        >
          Selecionar as guias para gerar lote
        </b-button>

        <b-button
          v-else-if="selection && selecteds.length > 0"
          variant="primary"
          :disabled="!userHasPermission('FatTissFat3')"
          @click="generateLot"
        >
          Gerar lote ( {{selecteds.length}} guia{{selecteds.length > 1 ? 's' : ''}} de {{ selectedType }} )
        </b-button>
        
        <b-dropdown v-if="invoiceSelected.status === TissInvoice.INVOICE_STATUS_CLOSED && !selection" text="Gerar lotes" variant="outline-primary" :disabled="!userHasPermission('FatTissFat3')">
          <b-dropdown-item
            href="#"
            v-for="(option, index) in batchOptions"
            :key="index"
            @click="
              option.text === 'Por associação (tipos de guias)' ? 
                generateLotsByAssociation(): 
                startGenerateLotsBySelection()
            "
          >
            {{ option.text }}
          </b-dropdown-item>
        </b-dropdown>

        <StatusButton 
          prefix="Status:"
          v-model="invoiceSelected.status"
          :disabled="!userHasPermission('FatTissFat4')"
          :statuses="tissInvoiceStatusOptions"
          @change="handleInvoiceSituation"
        />
      </div>
    </section>
    <hr />
  </section>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import { tissInvoiceStatusOptions, TissInvoice } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'InvoiceHeaderContainer',
  components: {
    Trash: () => import('@/assets/icons/trash.svg'),
    StatusButton: () => import('@/modules/tiss/manageGuides/components/StatusButton'),
  },
  props: {
    title: String,
    subtitles: Object,
    invoiceSelected: Object,
    invoiceStatus: String,
    selecteds: Array,
    selectedType: String,
    handleInvoiceSituation: {
      type: Function,
      required: true
    },
    startGenerateLotsBySelectionProp: {
      type: Function,
      default: () => {}
    },
    stopGenerateLotsBySelectionProp: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      TissInvoice,
      tissInvoiceStatusOptions,
      selection: false,
      batchOptions: [
        {text: 'Por seleção'},
        {text: 'Por associação (tipos de guias)'}
      ]
    }
  },
  methods: {
    userHasPermission,
    statusInvoice() {
      if(this.invoiceStatus === TissInvoice.INVOICE_STATUS_OPEN && this.selection){
        this.stopGenerateLotsBySelection()
      }
    },
    generateLotsByAssociation() {
      this.$emit('generateLotsByAssociation', this.invoiceSelected)
    },
    startGenerateLotsBySelection() {
      this.startGenerateLotsBySelectionProp()
      this.selection = true
    },
    stopGenerateLotsBySelection() {
      this.stopGenerateLotsBySelectionProp()
      this.selection = false
    },
    generateLot(){
      this.$emit('generateLotsBySelection', {
        invoice: this.invoiceSelected,
        selectedGuides: this.selecteds,
        selectedType: this.selectedType,

      })
    }
  },
  watch: {
    invoiceStatus: {
      handler: async function () {
        await this.statusInvoice()
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-container {
  .title {
    color: var(--dark-blue);
    letter-spacing: -0.01em;
    font-weight: 700;
    font-size: 18px;
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    p {
      font-weight: 700;
      font-size: 16px;
      margin-right: 20px;

      @media (max-width: 1368px) {
        font-size: 14px;
      }

      span {
        color: var(--blue-600);
        margin-right: 5px;
      }
    }
  }
}

.delete {
  padding: 4px;
  width: 56px;
  height: 36px;
  border: 2px solid var(--states-red-soft);
  border-radius: 8px;
  margin-top: 1px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn-title-container {
  div {
    margin-left: 5px;
  }
}

hr {
  width: 100%;
  color: var(--neutral-200);
}

.icon {
  width: 16px;
  height: 16px;

  &.trash {
    fill: none;
    stroke: var(--states-red-soft);
  }
}
</style>
